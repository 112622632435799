



























import { defineComponent, ref, nextTick, onUnmounted } from '@nuxtjs/composition-api';
import { onClickOutside } from '@vueuse/core';
import { useUiMode } from '~/composables';

export default defineComponent({
  name: 'Popover',
  props: {
    popoverContent: {
      type: String,
      default: '',
    },
    popoverReference: {
      type: String,
      default: '',
    },
  },
  setup () {
    const isContentShown = ref(false);
    const popoverContentRef = ref<HTMLDivElement>();
    const popoverTriggerRef = ref<HTMLDivElement>();

    const { isMobile } = useUiMode();

    const addScrollEvent = () => { window.addEventListener('scroll', onScroll, { passive: true }); };
    const removeScrollEvent = () => { window.removeEventListener('scroll', onScroll); };

    const showContent = async () => {
      addScrollEvent();
      isContentShown.value = true;

      await nextTick();
      if (!popoverContentRef.value || !popoverTriggerRef.value) {
        return;
      }

      const contentMarginInPixels = 5;
      const boundingRect = popoverTriggerRef.value.getBoundingClientRect();
      popoverContentRef.value.style.top = `${boundingRect.bottom + contentMarginInPixels}px`;

      const spaceToTheRight = window.innerWidth - boundingRect.left;
      const contentWidth = popoverContentRef.value.offsetWidth;
      if (spaceToTheRight < contentWidth) {
        popoverContentRef.value.style.right = '0px';
      }
    };
    const hideContent = () => {
      removeScrollEvent();
      isContentShown.value = false;
    };

    const onMouseover = () => {
      if (isMobile.value) return;
      showContent();
    };

    const onMouseout = () => {
      if (isMobile.value) return;
      hideContent();
    };

    const onScroll = () => {
      hideContent();
    };

    const onClick = () => {
      if (isContentShown.value) {
        hideContent();
      } else {
        showContent();
      }
    };

    onClickOutside(popoverTriggerRef, () => {
      hideContent();
    });

    onUnmounted(() => {
      removeScrollEvent();
    });

    return {
      onMouseover,
      onMouseout,
      onClick,
      onScroll,
      isContentShown,
      popoverContentRef,
      popoverTriggerRef,
    };
  },
});
