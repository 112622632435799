
































import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import Popover from '~/components/atoms/Popover.vue';
import { ENERGY_RATING_SIZE } from '~/constants/energyRating';
import type { EnergyRatingSize } from '~/constants/energyRating';
import type { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';

export default defineComponent({
  name: 'EnergyRating',
  components: {
    Popover,
  },
  props: {
    energyRating: {
      type: Object as PropType<EnergyRatingDetails>,
      required: true,
      validate: (value: EnergyRatingDetails) => {
        return value && value.ratingIconUrl && value.productCardUrl && value.labelUrl;
      },
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<EnergyRatingSize>,
      default: ENERGY_RATING_SIZE.DEFAULT,
      validator: (value: EnergyRatingSize) => {
        return Object.values(ENERGY_RATING_SIZE).includes(value);
      },
    },
  },
  setup(props) {
    const iconSizes = {
      [ENERGY_RATING_SIZE.SMALL]: 'min-h-[24px] max-h-[42px] max-w-[42px]',
      [ENERGY_RATING_SIZE.MEDIUM]: 'min-h-[34px] max-h-[60px] max-w-[60px]',
      [ENERGY_RATING_SIZE.DEFAULT]: 'min-h-[42px] max-h-[72px] max-w-[72px]',
    };
    const iconSize = computed(() => iconSizes[props.size]);

    const textSizes = {
      [ENERGY_RATING_SIZE.SMALL]: 'text-xs',
      [ENERGY_RATING_SIZE.MEDIUM]: 'text-xs',
      [ENERGY_RATING_SIZE.DEFAULT]: 'text-sm',
    };
    const textSize = computed(() => textSizes[props.size]);

    return {
      iconSize,
      textSize,
    };
  },
});
